import { CartResolverService } from './core/resolvers/cart-resolver.resolver';
import { ProductsResolver } from './core/resolvers/products-resolver.resolver';
import { HomeComponent } from './features/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TokenResolver } from './core/resolvers/token.resolver';
import { ExpiredComponent } from './features/errors/pages/expired/expired.component';
import { ErrorComponent } from './features/errors/pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      storeRoute: true
    }
  },
  {
    path: 'menu',
    loadChildren: () => import('./features/menu/menu.module').then(m => m.MenuModule)
  },
  {
    path: 'v/:token', resolve: { token: TokenResolver }, component: HomeComponent
  },
  {
    path: ':company/:shopId',
    loadChildren: () => import('./features/shop/shop.module').then(m => m.ShopModule),
  },
  {
    path: 'expired',
    component: ExpiredComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ProductsResolver, CartResolverService],
})
export class AppRoutingModule { }
