import { ShopService } from '../../core/services/shop.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  shops: any[] = [];
  errore: string = '';

  constructor(
    private negoziService: ShopService,
    private titleService: Title
  ) {
    this.titleService.setTitle('VisualOrder');
  }

  generateSkeleton(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  ngOnInit(): void {
    this.negoziService.getAllShops().subscribe((shops) => {
      this.shops = shops;
    });
  }
}
