import { SwUpdate } from '@angular/service-worker';
import { Component, OnInit, inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('out => in', [
        animate('300ms ease-in')
      ]),
      transition('in => out', [
        animate('300ms ease-out')
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'VisualOrder';
  date = new Date();

  private updated = inject(SwUpdate);
  private router = inject(Router);

  loading: boolean = true;
  loaderState: string = 'out';

  options: AnimationOptions = {
    path: '/assets/lottie/loader.json'
  };

  ngOnInit(): void {
    if (this.updated.isEnabled) {
      this.updated.versionUpdates.subscribe((event) => {
          if (event.type === 'VERSION_READY') {
            window.location.reload();
          }
      });
    }

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.loaderState = 'in';
        this.loading = true;
      }

      if (
        evt instanceof NavigationEnd ||
        evt instanceof NavigationCancel ||
        evt instanceof NavigationError
      ) {
        this.loaderState = 'out';
        this.loading = false;
      }
    });
  }
}
