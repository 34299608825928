import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle
} from '@angular/router';

interface StoredRoute {
  route: ActivatedRouteSnapshot,
  handler: DetachedRouteHandle
}
/**
 * Returns the full Path of a route, as a string
 */
function getFullPath(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map((v) => v.url.map(segment => segment.toString()).join("/"))
    .join('/')
    .trim()
    .replace(/\$/, "") // Remove trailing slash
}

export class RouteReuseService implements RouteReuseStrategy {
  // Oggetto Dictionary dove tengo le rotte salvate
  storedRoutes: Record<string, StoredRoute> = {};
  // Should we store the route? Defaults to false.
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.storeRoute
  }
  // Store the route
  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    const key = getFullPath(route)
    this.storedRoutes[key] = { route, handler };
  }
  // Should we retrieve a route from the store?
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = getFullPath(route)
    const isStored = !!route.routeConfig && !!this.storedRoutes[key]

    if (isStored) {
      const paramsMatch = this.compareObjects(
        route.params,
        this.storedRoutes[key].route.params
      )
      const queryParamsMatch = this.compareObjects(
        route.queryParams,
        this.storedRoutes[key].route.queryParams
      )

      return paramsMatch && queryParamsMatch
    }

    return false;
  }

  // Retrieve from the store (just the Handle)
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const key = getFullPath(route)
    if (!route.routeConfig || !this.storedRoutes[key]) return null;
    return this.storedRoutes[key].handler;
  }

  // Should the route be reused?
  shouldReuseRoute(
    previous: ActivatedRouteSnapshot,
    next: ActivatedRouteSnapshot
  ): boolean {
    const isSameConfig = previous.routeConfig === next.routeConfig;
    const shouldReuse = !next.data.noReuse;
    return isSameConfig && shouldReuse;
  }

  // Simple object comparison (from StackOverflow, needs to be double-checked)
  // Feel free to use a library!
  private compareObjects(a: any, b: any): boolean {
    // loop through all properties in base object
    for (let prop in a) {
      // determine if comparrison object has that property, if not: return false
      if (b.hasOwnProperty(prop)) {
        switch (typeof a[prop]) {
          // if one is object and other is not: return false
          // if they are both objects, recursively call this comparison function
          case "object":
            if (
              typeof b[prop] !== "object" ||
              !this.compareObjects(a[prop], b[prop])
            ) {
              return false;
            }
            break;
          // if one is function and other is not: return false
          // if both are functions, compare function.toString() results
          case "function":
            if (
              typeof b[prop] !== "function" ||
              a[prop].toString() !== b[prop].toString()
            ) {
              return false;
            }
            break;
          // otherwise, see if they are equal using coercive comparison
          default:
            if (a[prop] != b[prop]) {
              return false;
            }
        }
      } else {
        return false;
      }
    }
    return true;
  }
}
