import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Router, Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenResolver implements Resolve<boolean> {
  router = inject(Router);
  http = inject(HttpClient);
  auth = inject(AuthService);

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.paramMap.get('token');
    const queryParams = route.queryParams;

    return this.auth.validate(token).pipe(
      map(response => {
        if (response && response.access_token) {
          this.router.navigate(['/menu'], { queryParams, replaceUrl: true });
        }
        return null;
      }),
      catchError(error => {
        this.router.navigate(['/error'], { replaceUrl: true });
        return of(null);
      })
    );
  }

}
