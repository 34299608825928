<app-top-bar></app-top-bar>
<section class="section-background mb-3">
  <div class="fader">
    <div class="container">
      <h2>VisualOrder</h2>
      <h3>Acquista in piena sicurezza!</h3>
    </div>
  </div>
</section>

<div class="container mb-5" *ngIf="errore == ''; else errorContainer">
  <h3 class="fw-light">Punti vendita in evidenza</h3>
  <hr />
  <div class="row">
    <div class="col-md-12" *ngIf="shops.length; else skeletonTemplate">
      <div class="card card-product-grid mb-2 shadow-sm" *ngFor="let shop of shops"
        [routerLink]="shop.piva + '/' + shop.id">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="desc">
                <div class="img-wrap">
                  <img class="logo" [src]="shop.logo" *ngIf="shop.logo" [alt]="shop.insegna" />
                  <ngx-skeleton-loader *ngIf="!shop.logo" [theme]="{
                        height: '110px',
                        width: '110px',
                        'margin-bottom': '0'
                      }"></ngx-skeleton-loader>
                </div>
                <h4 href="#" class="title mb-0 text-truncate fw-light">
                  {{ shop.insegna }}
                </h4>
                <div class="info text-truncate">
                  {{ shop.slogan }}
                  <br *ngIf="shop.slogan" />
                  {{
                  shop.posizione.indirizzo +
                  " " +
                  shop.posizione.cap +
                  " " +
                  shop.posizione.citta +
                  " " +
                  shop.posizione.provincia
                  }}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 d-block d-sm-none text-center">
              <button type="button" class="btn btn-link fw-bold text-dark mt-2" style="flex: 1;">
                Entra nel negozio
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #skeletonTemplate>
      <div class="col-md-12" *ngFor="let item of generateSkeleton(6)">
        <div class="card card-product-grid mb-2 shadow-sm">
          <div class="card-body">
            <div class="row">
              <div class="col-md-9 col-sm-9">
                <div class="desc">
                  <figure class="img-wrap">
                    <ngx-skeleton-loader [theme]="{
                        height: '110px',
                        width: '110px',
                        'margin-bottom': '0'
                      }"></ngx-skeleton-loader>
                  </figure>
                  <div class="row">
                    <div class="col-12">
                      <ngx-skeleton-loader [theme]="{
                          height: '28px',
                          'margin-bottom': '0',
                          width: '150px'
                        }"></ngx-skeleton-loader>
                    </div>
                    <div class="col-12">
                      <ngx-skeleton-loader [theme]="{
                          height: '16px',
                          'margin-bottom': '0',
                          width: '130px'
                        }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 d-block d-sm-none text-center">
                <ngx-skeleton-loader [theme]="{ height: '38px', 'margin-bottom': '0' }"></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #errorContainer>
  <img src="./assets/images/error-429.svg" class="mt-5" width="100%" height="300px" />
  <h4 class="text-danger text-center mb-5 mt-5">{{ errore }}</h4>
</ng-template>

<app-footer></app-footer>
